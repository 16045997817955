import './index.css'

const Index = (props) => {
  const { title, desc, img } = props
  return (
    <a href='mailto:miciuscorp@gmail.com' className='tabs-item'>
      <img src={img} alt="" className='tabs-item-logo' />
      <div className='tabs-item-content'>
        <div className='tabs-item-content-title'>{title}</div>
        <div className='tabs-item-content-desc'>{desc}</div>
      </div>
    </a>
  )
}

export default Index