import { useEffect, useState } from 'react'
import logo from '../../assets/weibiaoti-2-12.svg'
import './index.css'
import TabsItem from './tabs-item'

const d1 = [
  {
    title: 'AI Tool1',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
]

const d2 = [
  {
    title: 'AI Tool2',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
]

const d3 = [
  {
    title: 'AI Tool3',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
  {
    title: 'AI Tool',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
]

const d4 = [
  {
    title: 'AI Tool4',
    img: logo,
    desc: '一款用来生成字体的工具',
  },
]

const Index = (props) => {
  const { data = [
    {
      title: 'Draw',
      key: 'Draw',
      contentData: d1
    },
    {
      title: 'Essay',
      key: 'Essay',
      contentData: d2
    },
    {
      title: 'Picture',
      key: 'Picture',
      contentData: d3
    },
    {
      title: 'Other',
      key: 'Other',
      contentData: d4
    }
  ]
  } = props

  const [activeKey, setActiveKey] = useState(data[0].key)
  const [contentData, setContentData] = useState([])

  useEffect(() => {
    const found = data.find(item => item.key === activeKey)
    setContentData([...found.contentData, ...found.contentData, ...found.contentData])
  }, [activeKey, data]);

  const handleTabsClick = (key) => {
    if (activeKey === key) {
      return
    }
    setActiveKey(key)
  }

  return (
    <div className='tabs'>
      <div className='tabs-title-container'>
        {
          data.map(item => (
            <>
              <span>
                <span className={
                  activeKey === item.key ? 'tabs-title tabs-title-active' : 'tabs-title'
                }
                  onClick={() => { handleTabsClick(item.key) }}>{item.title}</span>
              </span>
            </>
          ))
        }
      </div>
      <div className='tabs-content-container'>
        {
          contentData.map(contentItem => (
            <TabsItem {...contentItem} />
          ))
        }
      </div>
    </div>
  )
}

export default Index