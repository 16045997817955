import './App.css';
import Header from './components/Header';
import Tabs from './components/Tabs/tabs';
import Welcome from './components/Welcome';

function App() {
  return (
    <div className="app">
      <Header />
      <Welcome />
      <Tabs/>
    </div>
  );
}

export default App;
