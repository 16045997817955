import './index.css';
import PeopleIcon from '../../assets/people.svg'
import { useState } from 'react';
const Welcome = () => {
  const [showBrief, setShowBrief] = useState(false);

  const toggleBrief = () => {
    setShowBrief(o => !o);
  };

  return (
    <div className="welcome">
      <div className="welcome_main">
        <div className="welcome_main-left">
          <div className="title">
            <div>Powered</div>
            <div className="txt-lg">By AI</div>
            <div>Boost Your Productivity</div>
          </div>
          <div className="sub-title">This AI tools collection will boost your productivity and enhance your workflow</div>
          <div className='actions'>
            <a href='mailto:miciuscorp@gmail.com' className="btn-act line-gradient">Book a Demo</a>
            <a href='mailto:miciuscorp@gmail.com' className="btn-act">Build AI</a>
          </div>
        </div>
        <img className="welcome_main-right" alt='' src={PeopleIcon} />
      </div>
      <div className="welcome_bottom">
        <span className={`btn-act line-gradient ${showBrief && 'down active'}`} onClick={toggleBrief}>About</span>
      </div>
      {showBrief && (<div className="welcome_brief">
        Welcome to our AI Toolset! Our aim is to provide users with a powerful set of software and hardware tools that leverage the latest advancements in artificial intelligence to help people complete their tasks more efficiently.
        <br /><br />
        At the heart of our toolset is a commitment to providing our users with powerful and easy-to-use tools that can help them achieve their goals more quickly and effectively. Whether you're a data scientist, a researcher, or just someone who wants to harness the power of AI to get more done, we're confident that our AI Toolset can help you succeed.
        Thank you for considering our toolset, and we look forward to helping you achieve your goals!
      </div>)}
    </div>
  )
}

export default Welcome