import { useState } from 'react';
import Logo from '../../assets/logo.svg';
import MenuIcon from '../../assets/menu.svg'
import './index.css'
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(o => !o);
  }
  return (
    <header className='site-header'>
      <div className='brand'>
        <img className='logo' src={Logo} alt='' /><span>micius</span>
      </div>
      <div className={`site-nav ${showMenu && 'active'}`}>
        <a href="mailto:miciuscorp@gmail.com" className="link">Products</a>
        <a href="mailto:miciuscorp@gmail.com" className="link">Solutions</a>
        <a href="mailto:miciuscorp@gmail.com" className="link">Customers</a>
        <a href="mailto:miciuscorp@gmail.com" className="link">Pricing</a>
        <a href="mailto:miciuscorp@gmail.com" className="link">Resources</a>
        <a href="mailto:miciuscorp@gmail.com" className="link">Company</a>
      </div>
      <a href="mailto:miciuscorp@gmail.com" className="login">LOG IN</a>
      <img className='btn-mob-menu' onClick={toggleMenu} src={MenuIcon} alt='' />
    </header>
  )
};

export default Header;